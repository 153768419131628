import {
    collection,
    addDoc,
    doc,
    updateDoc,
    deleteDoc,
} from 'firebase/firestore';


import { firestore } from '../firebase/firebase';

class ContactService {

    // Adds a new contact into Firebase
    async createContact(contact) {
        const collectionReference = collection(firestore, 'contacts');
        const docRef = await addDoc(collectionReference, {
            first_name: contact.first,
            last_name: contact.last,
            email: contact.email,
            message: contact.message,
        });
        contact.id = docRef.id;
        return contact;
    }

    // Read all contacts stored in Firebase into an array (potentially for future use, might be obsolete - NEED TO RESOLVE THIS)
    // async readContacts() {
    //     const collectionReference = collection(firestore, 'contacts');
    //     const q = query(collectionReference);
    //     const querySnapshot = await getDocs(q);
    //     const contacts = [];

    //     querySnapshot.forEach((doc) => {

    //         const data = doc.data();

    //         const contact = new Contact(
    //             doc.id,
    //             data.name,
    //             data.email,
    //         );
    //         contacts.push(contact);
    //     });

    // }

    // Update contact information on Firebase (potentially for future developer use)
    async updateContact(contact) {
        const docRef = doc(firestore, 'contacts', contact.id);
        await updateDoc(docRef, {
            name: contact.name,
            email: contact.email,
            message: contact.message,
        });
        return contact;
    }

    // Delete contact information on Firebase (potentially for future developer use)
    async deleteContact(contact) {
        const docRef = doc(firestore, 'contacts', contact.id);
        await deleteDoc(docRef);
    }
}

const service = new ContactService();
export default service;