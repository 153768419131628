// Model for storing contact info in Firebase


export class Contact {
    constructor(id, first, last, email, message) {
        this.id = id;
        this.first = first;
        this.last = last;
        this.email = email;
        this.message = message;
    }
}