import React, { useState } from "react";
import { BsChevronDown } from "react-icons/bs";
// import $ from "jquery";
import ScrollToTop from "./Scroll";

export default function HOME() {
  const [chevOpacity, setChevOpacity] = useState(0);

  function scrollFunc() {
    var y = window.pageYOffset;
    // console.log(y);
    if (y >= 100) {
      setChevOpacity((y - 100) * 0.01);
    } else {
      setChevOpacity(0);
    }
  }

  window.addEventListener("scroll", scrollFunc);

  return (
    <><ScrollToTop /><div>
      {/* TITLE IMAGE */}
      <div className="d-flex justify-content-center">
        <div
          style={{
            position: "absolute",
            marginTop: "300px",
            marginLeft: "5%",
            marginRight: "5%",
          }}
        >
          <h1 className="lulo whi">
            Waste to Feed
            <br />
            Bioconversion:
          </h1>
          <h2 className="lulo whi mt-3" style={{ marginBottom: "200px" }}>
            Turning Problems into Profits
          </h2>
          <BsChevronDown
            id="chevron"
            color="white"
            style={{ marginLeft: "12%", opacity: chevOpacity }} />
        </div>
        <img
          src={require("./assets/home_background.png")}
          style={{ width: "100%", height: "965px", objectFit: "cover" }}
          alt="home_background" />
      </div>
      {/* THE CHALLENGE */}
      <div id='thechallenge' className='container-fluid d-flex justify-content-center nav' style={{ paddingBottom: '0px', textAlign: 'center' }}>
        <h1 className='lulo whi' style={{ marginBottom: '30px' }}>The Challenge</h1>
        <div className='container-fluid' style={{ position: 'relative', marginTop: '100px', width: '80%' }}>
          <div id='challenges' className='d-flex' style={{ marginBottom: '-17px' }}>
            <div id='mow' className='col challengebox'>
              <h1 id='mowtext' className='tli helv'>
                01
              </h1>
              <h3 
                className='whi helv mb-4 mt-4' 
                style={{ textAlign: 'left' }}>
                  MOUNTAINS OF<br />WASTE
              </h3>
              <img 
                src={require('./assets/mowimg.png')} 
                style={{ width: '100%', borderRadius: '10px' }} 
                alt='mowimg'>
              </img>
              <p id='mowdesc' className='whi helv mt-4'>
                Cities generate over 1.3-billion tons of food waste per year full 
                of nutrients which are normally left to rot in a landfill somewhere.
              </p>
            </div>
            <div id="eid" className="col challengebox d-flex flex-column align-items-start">
              <h1 id="eidtext" className="tli helv">
                02
              </h1>
              <h3 
                className="whi helv mb-4 mt-4" 
                style={{ textAlign: "left" }}>
                INCREASING<br />DEMAND FOR FOOD
              </h3>
              <img
                src={require("./assets/eidimg.png")}
                style={{ width: "100%", borderRadius: "10px" }}
                alt="eidimg">
              </img>
              <p id="eiddesc" className="whi helv mt-4">
                By 2050, our cities will be home to 80% of the world's
                population and the demand for food will grow by a further 60%
                from where it is now.
              </p>
            </div>
            <div id="eotb" className="col challengebox d-flex flex-column align-items-start">
              <h1 id="eotbtext" className="tli helv">
                03
              </h1>
              <h3 className="whi helv mb-4 mt-4" 
              style={{ textAlign: "left" }}>
                ECOSYSTEM ON<br />THE BRINK
              </h3>
              <img
                src={require("./assets/eotbimg.png")}
                style={{ width: "100%", borderRadius: "10px" }}
                alt="eotbimg">
              </img>
              <p id="eotbdesc" className="whi helv mt-4">
              <br />Our ecosystem that feeds us is overexploited and cannot sustain
                any further impact.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* MEET THE BLACK SOLDIER FLY */}
      <div id='blacksoldierfly' className='container-fluid tda' style={{ textAlign: 'center' }}>
        <h1 className='lulo whi' style={{ marginBottom: '30px' }}>MEET THE BLACK<br />SOLDIER FLY (BSF)</h1>
        <img id='fly' src={require('./assets/fly.png')} alt='fly' style={{ width: '60%' }} />
        <h2 className='whi helv fw-bold'>Converting food waste into valuable proteins &<br />fats for the animal feed sector</h2>
        <a href='/technology'>
          <button type='button' className='button mt-5' style={{ width: '68vw' }}>LEARN MORE</button>
        </a>
      </div>
    </div></>
  );
}
