import React, { useEffect } from "react";
import $ from "jquery";
import { BsFillHexagonFill } from "react-icons/bs";

import { Link } from "react-router-dom";

import ScrollToTop from "./Scroll";

export default function ABOUT() {
  // useEffect(
  //   () =>
  //     function () {
  //       $(
  //         "#visiontext, #missiontext, #solutiontext, #uniquevaluetext, #endproducttext, #impacttext"
  //       ).hide();
  //       $("#impact")
  //         .on("mouseenter", function () {
  //           $("#impacthex").stop().css("color", "white");
  //           $("#impacttext").stop().fadeIn(150);
  //         })
  //         .on("mouseleave", function () {
  //           $("#impacthex").stop().css("color", "rgb(251,147,52)");
  //           $("#impacttext").stop().fadeOut(150);
  //         });
  //       $("#endproduct")
  //         .on("mouseenter", function () {
  //           $("#endproducthex").stop().css("color", "white");
  //           $("#endproducttext").stop().fadeIn(150);
  //         })
  //         .on("mouseleave", function () {
  //           $("#endproducthex").stop().css("color", "rgb(251,147,52)");
  //           $("#endproducttext").stop().fadeOut(150);
  //         });
  //       $("#vision")
  //         .on("mouseenter", function () {
  //           $("#visionhex").stop().css("color", "white");
  //           $("#visiontext").stop().fadeIn(150);
  //         })
  //         .on("mouseleave", function () {
  //           $("#visionhex").stop().css("color", "rgb(251,147,52)");
  //           $("#visiontext").stop().fadeOut(150);
  //         });
  //       $("#uniquevalue")
  //         .on("mouseenter", function () {
  //           $("#uniquevaluehex").stop().css("color", "white");
  //           $("#uniquevaluetext").stop().fadeIn(150);
  //         })
  //         .on("mouseleave", function () {
  //           $("#uniquevaluehex").stop().css("color", "rgb(251,147,52)");
  //           $("#uniquevaluetext").stop().fadeOut(150);
  //         });
  //       $("#mission")
  //         .on("mouseenter", function () {
  //           $("#missionhex").stop().css("color", "white");
  //           $("#missiontext").stop().fadeIn(150);
  //         })
  //         .on("mouseleave", function () {
  //           $("#missionhex").stop().css("color", "rgb(251,147,52)");
  //           $("#missiontext").stop().fadeOut(150);
  //         });
  //       $("#solution")
  //         .on("mouseenter", function () {
  //           $("#solutionhex").stop().css("color", "white");
  //           $("#solutiontext").stop().fadeIn(150);
  //         })
  //         .on("mouseleave", function () {
  //           $("#solutionhex").stop().css("color", "rgb(251,147,52)");
  //           $("#solutiontext").stop().fadeOut(150);
  //         });
  //     }
  // );
  return (
    <><ScrollToTop /><div>
      {/* TOP IMAGE */}
      <div className="non-home-background-container">
        <img
          src={require("./assets/home_background.png")}
          style={{ width: "100%", height: "965px", objectFit: "cover" }}
          alt="home_background" />
      </div>
      <div className="container-fluid tda p-0 d-flex flex-column align-items-center">
        {/* ABOUT US SECTION */}
        <img
          id="firstfly"
          src={require("./assets/first fly.png")}
          style={{ borderRadius: "0px 0px 20px 20px" }}
          alt="fly on netting" />
        <div
          className="d-flex flex-column container-fluid align-items-center"
          style={{
            height: "1350px",
            paddingTop: "2vw",
            paddingLeft: "0",
            paddingRight: "0",
          }}
        >
          <h1 className="lulo gre text-center" style={{ marginBottom: "5vw" }}>
            ABOUT US
          </h1>

          <div className="d-flex container-fluid justify-content-start align-items-center p-0">
            <div
              className="abouthighlight tli d-flex justify-content-end"
              style={{
                width: "40%",
                zIndex: "0",
                left: "0",
                borderRadius: "0 20px 20px 0",
              }}
            >
              <p
                className="whi helv"
                style={{ textAlign: "left", marginLeft: "37%", zIndex: "1" }}
              >
                Nutrisek exists for the purpose of helping our customers get rid
                of troublesome organic waste streams in the most ecologically
                efficient way possible.
              </p>
            </div>
            <img
              src={require("./assets/leaves.png")}
              alt="leaves"
              style={{ width: "15vw", marginLeft: "60px" }} />
          </div>
          <div className="d-flex container-fluid justify-content-end align-items-center p-0">
            <img
              src={require("./assets/recycling.png")}
              alt="recycling"
              style={{ width: "15vw", marginRight: "40px" }} />
            <div
              className="abouthighlight tli d-flex justify-content-start"
              style={{
                width: "52.5%",
                zIndex: "0",
                right: "0",
                borderRadius: "20px 0 0 20px",
              }}
            >
              <p
                className="whi helv"
                style={{
                  textAlign: "right",
                  marginRight: "28%",
                  zIndex: "1",
                }}
              >
                Our bug-based nutrient recycling process is natural,
                chemical-free and renewably powered in order to maximise the
                carbon offset and sustainable impact potential for those who are
                passionate about meeting their Zero Waste goals, ESG Commitments
                and Emission Reduction targets.
              </p>
            </div>
          </div>
          <div
            className="d-flex container justify-content-center align-items-center"
            style={{ width: "100%", marginTop: "70px" }}
          >
            <p className="whi helv">
              Nutrisek provides municipalities, food
              producers, processors, retailers, waste collection companies and
              eco-entrepreneurs with a turnkey, waste diversion solution, that
              recycles their unwanted waste into climate friendly insect
              protein, oil and soil for use as primary inputs back into the food
              supply chain to achieve maximum nutritional circularity.
            </p>
          </div>

          <div class="row ">
            <div class="column">
              <img
                id="aboutbottom"
                src={require("./assets/about bottom.png")}
                alt="food waste"
                style={{
                  height: "300px",
                  borderRadius: "2rem",
                  marginTop: "5vw",
                }} />
            </div>


          </div>
        </div>

        <div class="Infographics tli m-5 p-5" style={{
                borderRadius: "20px 20px 20px 20px",
                
              }}>
          <h2 className="lulo gre">VISION</h2>
          <h6 class="text-end pb-3">-Future proof our food system by bringing circularity back to its core</h6>

          <h2 className="lulo gre">MISSION</h2>
          <h6 class="text-end pb-3">-Upcycling food waste into sustainable animal feed ingredients</h6>

          <h2 className="lulo gre">SOLUTION</h2>
          <h6 class="text-end pb-3">-Provide bug-based bioconversion as a service to waste generators and handlers</h6>

          <h2 className="lulo gre">UNIQUE VALUE</h2>
          <h6 class="text-end pb-3">-An automated, modular, medium-scale, BSF bioreactor</h6>

          <h2 className="lulo gre">END PRODUCT</h2>
          <h6 class="text-end pb-3">-100 tpa of high quality insect protein and oil per bioreactor module</h6>

          <h2 className="lulo gre">IMPACT</h2>
          <h6 class="text-center ">-Equivalent to 200t of soy, 600 hectares of rainforest offsetting 3000t of CO2 per acre</h6>

        </div>

        {/* INFOGRAPHIC
        <div id="infographic" className="container-fluid">
          <div
            id="hexagons"
            className="d-flex justify-content-end"
            style={{ zIndex: 1, position: "relative" }}
          >
            <div className="row left" style={{ position: "relative" }}>
              <div id="impact">
                <BsFillHexagonFill
                  id="impacthex"
                  style={{ position: "absolute" }} />
                <h1
                  className="lulo whi"
                  style={{
                    position: "absolute",
                    fontSize: "20px",
                    top: "145px",
                  }}
                >
                  IMPACT
                </h1>
              </div>
              <div id="endproduct">
                <BsFillHexagonFill
                  id="endproducthex"
                  style={{ position: "absolute" }} />
                <h1
                  className="lulo whi"
                  style={{
                    position: "absolute",
                    textAlign: "center",
                    fontSize: "20px",
                    top: "310px",
                    left: "25px",
                  }}
                >
                  END
                  <br />
                  PRODUCT
                </h1>
              </div>
            </div>
            <div className="row center" style={{ position: "relative" }}>
              <div id="vision">
                <BsFillHexagonFill
                  id="visionhex"
                  style={{ position: "absolute" }} />
                <h1
                  className="lulo whi"
                  style={{
                    position: "absolute",
                    fontSize: "20px",
                    top: "100px",
                    left: "40px",
                  }}
                >
                  VISION
                </h1>
              </div>
              <div id="uniquevalue">
                <BsFillHexagonFill
                  id="uniquevaluehex"
                  style={{ position: "absolute" }} />
                <h1
                  className="lulo whi"
                  style={{
                    position: "absolute",
                    textAlign: "center",
                    fontSize: "20px",
                    left: "35px",
                    top: "450px",
                  }}
                >
                  UNIQUE
                  <br />
                  VALUE
                </h1>
              </div>
            </div>
            <div className="row right" style={{ position: "relative" }}>
              <div id="mission">
                <BsFillHexagonFill
                  id="missionhex"
                  style={{ position: "absolute" }} />
                <h1
                  className="lulo whi"
                  style={{
                    position: "absolute",
                    fontSize: "20px",
                    top: "140px",
                    left: "32px",
                  }}
                >
                  MISSION
                </h1>
              </div>
              <div id="solution">
                <BsFillHexagonFill
                  id="solutionhex"
                  style={{ position: "absolute" }} />
                <h1
                  className="lulo whi"
                  style={{
                    position: "absolute",
                    fontSize: "20px",
                    top: "325px",
                    left: "20px",
                  }}
                >
                  SOLUTION
                </h1>
              </div>
            </div>
          </div> */}
          {/* <div
            id="darkbg"
            className="nav"
            style={{ position: "absolute", width: "100%", zIndex: "0" }}
          ></div> */}
          {/*<div id="text" style={{ position: "relative", marginRight: "10%" }}>
            <div id="visiontext" style={{ position: "absolute" }}>
              <h1 className="whi lulo" style={{ marginLeft: "100px" }}>
                VISION
              </h1>
              <p
                className="whi helv"
                style={{ marginLeft: "100px", fontSize: "20px" }}
              >
                Future proof our food system by bringing circularity back to its
                core
              </p>
            </div>
            <div id="missiontext" style={{ position: "absolute" }}>
              <h1 className="whi lulo" style={{ marginLeft: "100px" }}>
                MISSION
              </h1>
              <p
                className="whi helv"
                style={{ marginLeft: "100px", fontSize: "20px" }}
              >
                Upcycling food waste into sustainable animal feed ingredients
              </p>
            </div>
            <div id="solutiontext" style={{ position: "absolute" }}>
              <h1 className="whi lulo" style={{ marginLeft: "100px" }}>
                SOLUTION
              </h1>
              <p
                className="whi helv"
                style={{ marginLeft: "100px", fontSize: "20px" }}
              >
                Provide bug-based bioconversion as a service to waste generators
                and handlers
              </p>
            </div>
            <div id="uniquevaluetext" style={{ position: "absolute" }}>
              <h1 className="whi lulo" style={{ marginLeft: "100px" }}>
                UNIQUE VALUE
              </h1>
              <p
                className="whi helv"
                style={{ marginLeft: "100px", fontSize: "20px" }}
              >
                An automated, modular, medium-scale, BSF bioreactor
              </p>
            </div>
            <div id="endproducttext" style={{ position: "absolute" }}>
              <h1 className="whi lulo" style={{ marginLeft: "100px" }}>
                END PRODUCT
              </h1>
              <p
                className="whi helv"
                style={{ marginLeft: "100px", fontSize: "20px" }}
              >
                100 tpa of high quality insect protein and oil per bioreactor
                module
              </p>
            </div>
            <div id="impacttext" style={{ position: "absolute" }}>
              <h1 className="whi lulo" style={{ marginLeft: "100px" }}>
                IMPACT
              </h1>
              <p
                className="whi helv"
                style={{ marginLeft: "100px", fontSize: "20px" }}
              >
                Equivalent to 200t of soy, 600 hectares of rainforest offsetting
                3000t of CO2 per acre
              </p>
            </div>
                </div>*/}
        
        <Link
          to="/contact"
          className="container-fluid d-flex justify-content-center"
        >
          <button className="button mb-5" style={{ width: "68vw" }}>
            GET IN TOUCH
          </button>
        </Link>
      </div>
      </div>
    </>
    
  );
}
