import React from "react";

import logo from "../../pages/assets/inverted new.png";
import { Link } from "react-router-dom";
// import logo from "../../pages/assets/temp.png"

export default function Footer() {
  return (
    <div className="main-footer">
      <div
        className="foot-container d-flex justify-content-evenly"
        style={{ paddingTop: "2vw", color: "white" }}
      >
        <div className="row">
          <hr></hr>

          <div className=" fot-logo col text-center position-top">
            <img
              style={{ width: "100px", height: "100px" }}
              src={logo}
              alt="Logo"
            />
          
          </div>

          <div className="col">
            {/* <div className='logo'>
                    <image src={require('./assets/temp.png').default} ></image>
                </div> */}
          </div>

          <div className="col">
            {/* <image src={require('./assets/temp.png').default} ></image> */}

            <h2>NUTRISEK</h2>
            {/* <img className='mainfot-logo' src={logo} alt="Logo"/> */}
            <p>Bug-Powered Nutrient Recycling </p>
          </div>

          <div className="col">
            <h3>LINK</h3>
            <ul className="list-unstyled">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about">About</Link>
              </li>
              <li>
                <Link to="/advantage">Advantage</Link>
              </li>
              <li>
                <Link to="/technology">Technology</Link>
              </li>
              <li>
                <Link to="/contact">Contact </Link>
              </li>
            </ul>
          </div>

          <div className="col">
            <h3>CONTACT</h3>
            <ul className="list-unstyled">
              <li>Nutrisek (Pty) Ltd, Capricorn</li>
              <li>Technology Park, 1835</li>
              <li>Capricorn Crescent,</li>
              <li>Muizenberg, Cape Town, 7948</li>
              <li>+27 83 395 1860</li>
              <li>cobus@nutrisek.com</li>
            </ul>
          </div>

          <hr></hr>

          <div className="row">
            <span className="col-sm text-center fw-lighter">
              <p className="col-sm helv">
                &copy;{new Date().getFullYear()} NUTRISEK | All Rights Reserved
                | Terms of Service | Privacy
              </p>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}