import React, { useState } from "react";
import { FaMapMarkerAlt } from "react-icons/fa";
import "../../App.css";
import ScrollToTop from "./Scroll";
import { Contact } from '../../models/contact.js';

// These imports would be part of Firebase implementation.
// import { Contact } from "../../models/contact";
// import ContactService from "../../services/contact_service.js";

export default function CONTACT(props) {
  const [firstName, setFirstName]  = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');


  function sendInfo(event) {
    event.preventDefault();
    

    // create a new contact
    const contact = new Contact(
      null,
      firstName,
      lastName,
      email,
      message,
    );

    props.onContactCreated(contact);

    setFirstName('');
    setLastName('');
    setEmail('');
    setMessage('');
  }


  return (
    <><ScrollToTop /><div>
      {/* TOP IMAGE */}
      <div className="non-home-background-container">
        <img
          src={require("./assets/home_background.png")}
          style={{ width: "100%", height: "965px", objectFit: "cover" }}
          alt="home_background" />
      </div>
      <div className="tda d-flex flex-column align-items-center">
        <div
          className="container-fluid tda d-flex flex-column"
          style={{ paddingBottom: "3vw", alignItems: "center" }}
        >
          <div id="generalinfo" className="container">
            <h1
              className="lulo gre"
              style={{ fontSize: "55px", marginTop: "60px", marginBottom: "50px" }}
            >
              {" "}
              Make Sustainability Happen{" "}
            </h1>
            <div className="d-flex">
              <a href="https://goo.gl/maps/f7teS1k7T2aw9Kic9">
                <FaMapMarkerAlt
                  style={{
                    scale: "250%",
                    marginTop: "18px",
                    marginLeft: "15px",
                    marginRight: "30px",
                    color: "white",
                  }}
                ></FaMapMarkerAlt>
              </a>
              <p className="helv whi" style={{ width: "70%" }}>
                Nutrisek (Pty) Ltd, Capricorn Technology Park, 1835 Capricorn
                Crescent, Muizenberg, Cape Town, 7948
              </p>
            </div>
          </div>

          <div
            id="form"
            className="d-flex flex-column align-items-start tli mt-5"
            style={{ padding: "5vw" }}
          >
            <h2 className="helv fw-bold whi"> Contact Us </h2>
            <hr style={{ width: "100%" }}></hr>
            <div className="d-flex" style={{ width: "100%" }}>
              <div style={{ marginRight: "20px" }}>
                <h3 className="helv fw-bold whi">Phone</h3>
                <p className="whi">+27 83 395 1860</p>
              </div>

              <div>
                <h3 className="helv fw-bold whi">Email</h3>
                <p className="whi">cobus@nutrisek.com</p>
              </div>
            </div>

            <form style={{ width: "100%" }}>
              <div className="d-flex flex-wrap mt-3">
                <div style={{ marginRight: "10px", flexGrow: "1" }}>
                  <p className="helv whi m-1">First Name</p>
                  <input value={firstName} onChange={(event) => setFirstName(event.target.value)} type="text" className="form-control"></input>
                </div>

                <div style={{ marginRight: "10px", flexGrow: "1" }}>
                  <p className="helv whi m-1">Last Name</p>
                  <input value={lastName} onChange={(event) => setLastName(event.target.value)} type="text" className="form-control"></input>
                </div>

                <div style={{ flexGrow: "7" }}>
                  <p className="helv whi m-1">Email</p>
                  <input value={email} onChange={(event) => setEmail(event.target.value)} type="email" className="form-control"></input>
                </div>
              </div>

              <div className="d-grid mt-3">
                <p className="helv whi m-1">Message</p>
                <textarea value={message} onChange={(event) => setMessage(event.target.value)} type="text" className="form-control" style={{ height: "200px" }}></textarea>
              </div>
            </form>
          </div>
          <button
            onClick={sendInfo}
            className="button mt-3"
            style={{ width: "100%" }}
          >
            Submit
          </button>
        </div>
      </div>
    </div></>
  );
}
