import React from "react";
import tgraphic from "./assets/waste cycle.png";
import tray from "./assets/Setting trays.jpeg";
import ScrollToTop from "./Scroll";

// import { BsDashLg } from "react-icons/bs";

export default function ADVANTAGE() {
  return (
    <><ScrollToTop /><div>
      {/* TOP IMAGE */}
      <div className="non-home-background-container">
        <img
          src={require("./assets/home_background.png")}
          style={{ width: "100%", height: "965px", objectFit: "cover" }}
          alt="home_background" />
      </div>
      <div
        className="d-flex flex-column align-items-center nav"
        style={{ marginBottom: "-650px", padding: "0px", textAlign: "center", height: "3800px" }}
      >
        <div
          className="o-tech"
          style={{ padding: "0", width: "100%", height: "600px" }}
        >
          <h1
            className="lulo gre text-left"
            style={{
              fontSize: "55px",
              textAlign: "center",
              marginLeft: "-650px",
              marginTop: "40px",
            }}
          >
            {" "}
            Our <br></br>Advantage
          </h1>
          <div
            className="d-flex container justify-content-around "
            style={{ width: "68vw" }}
          >
            <p
              className="helv whi"
              style={{
                fontSize: "17px",
                textAlign: "left",
                marginTop: "25px",
                width: "500px",
                marginLeft: "-650px",
              }}
            >
              Processing food waste into something more valuable is hard. Food waste is
              generated at many different locations in small quantities, is often contaminated
              with plastics, and has a low nutrient density.<br></br><br></br>Other biological waste treatment
              options like biogas and composting are technically limited and generate low value
              end products.<br></br><br></br>The Nutrisek process extracts maximum value from waste making decentralised
              waste processing possible and profitable at a 20 ton per day scale.

              <br></br>
            </p>
          </div>

          <div
            className="tray-img"
            style={{
              padding: "0",
              width: "450px",
              height: "content",
              marginLeft: "fill-content.px",
              marginRight: "0px",
              marginTop: "-572px",
            }}
          >
            <img
              style={{
                height: "100%",
                width: " 100%",
                marginTop: "45px",
                marginLeft: "950px",
              }}
              src={tray}
              alt="Logo" />
          </div>
        </div>

        <div
          className="Nutr-Loop d-flex flex-column align-items-center"
          style={{ width: "100%", height: "700px" }}
        >
          <h1
            className="lulo gre text-center"
            style={{
              fontSize: "42px",
              marginBottom: "-90px",
              marginTop: "100px",
            }}
          >
            CLOSING THE NUTRIENT LOOP
          </h1>
          <h2
            className="whi helv"
            style={{
              fontSize: "18px",
              textAlign: "center",
              marginTop: "150px",
              width: "68vw",
            }}
          >
            Over 70% of all emissions related to industrial animal rearing is
            attributed to producing the feed they consume which make the
            sourcing of low carbon ingredients a top priority for many feed
            manufacturers
          </h2>

          <img
            style={{ width: "800px", marginTop: "50px" }}
            src={tgraphic}
            alt="Logo" />
        </div>

        <div
          className="Citi-img"
          style={{ padding: "20%", width: "100%", height: "1000px" }}
        >
          <div
            className="cit-text text-center"
            style={{
              padding: "0%",
              marginTop: "-200px",
              width: "500px",
              height: "500px",
            }}
          >
            <h1
              className="lulo whi"
              style={{
                fontSize: "27px",
                textAlign: "left",
                padding: "2rem",
              }}
            >
              In the realm of major continuous challenges,
              <mark className="red">cities</mark><br></br>
              are key leverage points in the quest for global sustainability.
            </h1>
            <p
              className="whi helv"
              style={{
                textAlign: "left",
                fontSize: "17px",
                width: "500px",
                padding: "2rem",
              }}
            >
              Improving resource efficiency in cities provides an opportunity to
              address environmental and social challenges while creating
              significant financial opportunity in return.
            </p>
            <div
              className=" our-r d-grid gap-2"
              style={{
                fontSize: "15px",
                marginLeft: "-18px",
                padding: "20px",
                marginTop: "60px",
              }}
            >

            </div>
          </div>
        </div>
        <div
          className="card-graphic"
          style={{ width: "100%", height: "850px" }}
        >
          <h1
            className="lulo whi text-center "
            style={{
              fontSize: "53px",
              marginBottom: "-160px",
              marginTop: "100px",
            }}
          >
            {" "}
            IMPACT IN NUMBERS{" "}
          </h1>

          <div className="container-fluid" style={{ width: "68vw" }}>
            <div
              className=" card-contaier d-flex flex-row "
              style={{
                marginTop: "100px",
                marginLeft: "10px",
                marginRight: "5px",
              }}
            >
              <div
                className="col crd"
                style={{
                  marginRight: "-50px",
                  marginTop: "150px",
                  width: "300px",
                  height: "550px",
                }}
              >
                <h4
                  class="lulo whi text-center"
                  style={{
                    fontSize: "39px",
                    marginBottom: "-90px",
                    marginTop: "100px",
                  }}
                >
                  3rd
                </h4>
                <p
                  className="whi helv text-center"
                  style={{
                    textAlign: "center",
                    fontSize: "18px",
                    marginTop: "60px",
                    padding: "2rem",
                  }}
                >
                  {/* <BsDashLg style={{width:'300px'}}></BsDashLg> */}
                  <br></br><br></br><br></br>
                  If food waste were a country it would be the 3rd largest emitter of greenhouse gases globally.
                </p>
              </div>

              <div
                className="col crd"
                style={{
                  marginLeft: "100px",
                  marginRight: "-50px",
                  marginTop: "150px",
                  width: "300px",
                  height: "550px",
                }}
              >
                <h4
                  className=" lulo whi text-center "
                  style={{
                    fontSize: "39px",
                    marginBottom: "-90px",
                    marginTop: "100px",
                  }}
                >
                  $1 TN
                </h4>
                <p
                  className="whi helv text-center"
                  style={{
                    textAlign: "center",
                    fontSize: "18px",
                    marginTop: "60px",
                    padding: "2rem",
                  }}
                >
                  {/* <BsDashLg style={{width:'100%'}}></BsDashLg> */}
                  <br></br><br></br><br></br>The economic value of the destructive impact of food waste on the global economy annually.
                </p>
              </div>

              <div
                className="col crd"
                style={{
                  marginLeft: "100px",
                  marginRight: "-50px",
                  marginTop: "150px",
                  width: "300px",
                  height: "550px",
                }}
              >
                <h4
                  className=" lulo whi text-center"
                  style={{
                    fontSize: "39px",
                    marginBottom: "-90px",
                    marginTop: "80px",
                  }}
                >
                  3000 tons
                </h4>
                <p
                  className="whi helv text-center"
                  style={{
                    textAlign: "center",
                    fontSize: "18px",
                    marginTop: "60px",
                    padding: "2rem",
                  }}
                >
                  {/* <BsDashLg style={{width:'100%'}}></BsDashLg> */}
                  <br></br><br></br>
                  The quantity of C02 emissions avoided per bioreactor annually.

                </p>
              </div>

              <div
                className="col crd"
                style={{
                  marginLeft: "100px",
                  marginTop: "150px",
                  width: "300px",
                  height: "550px",
                }}
              >
                <h4
                  className="lulo whi text-center "
                  style={{
                    fontSize: "39px",
                    marginBottom: "-90px",
                    marginTop: "80px",
                  }}
                >
                  4 <br></br>tons
                </h4>
                <p
                  className="whi helv text-center"
                  style={{
                    textAlign: "center",
                    fontSize: "18px",
                    marginTop: "60px",
                    padding: "2rem",
                  }}
                >
                  {/* <BsDashLg style={{width:'100%'}}></BsDashLg> */}
                  <br></br><br></br>
                  The quantity of wild fish that is left in the ocean when 1 ton of dried larvae is produced and substituted for fishmeal.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div></>
  );
}
